import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import kebabCase from 'lodash/kebabCase'

import SEO from '~/components/shared/Seo'
import {
  AllStrapiArticle,
  AllStrapiCategory,
  AllStrapiTag,
  StrapiTag,
} from '~/types/graphql'
import { PagePropsWithContext, WithRequired } from '~/types/helper'
import { fileToImageLikeData } from '~/utils'
import TagsView from '~/views/Blog/views/Tags.view'

export type TagsQueryResult = StrapiTag &
  AllStrapiArticle &
  AllStrapiTag &
  AllStrapiCategory

export const Head = ({
  data,
  pageContext,
}: PagePropsWithContext<TagsQueryResult>) => {
  const { humanPageNumber, previousPagePath, nextPagePath } = pageContext
  const { h1, url, title, meta_description, og_image } =
    data.strapiTag as WithRequired<Queries.STRAPI_TAG, 'og_image'> & {
      title: string
      meta_description: string
    }

  const seoUrl =
    humanPageNumber === 1
      ? `https://www.codica.com/blog/tag/${kebabCase(url || '')}/`
      : `https://www.codica.com/blog/tag/${kebabCase(
          url || '',
        )}/page/${humanPageNumber}/`

  const seoNumPage = humanPageNumber > 1 ? ` - Page ${humanPageNumber}` : ''

  return (
    <>
      {previousPagePath && (
        <link rel="prev" href={`https://www.codica.com${previousPagePath}`} />
      )}
      {nextPagePath && (
        <link rel="next" href={`https://www.codica.com${nextPagePath}`} />
      )}

      <SEO
        title={`${title}${seoNumPage}`}
        description={`${meta_description}${seoNumPage}`}
        urlImage={getSrc(fileToImageLikeData(og_image.localFile)) || ''}
        url={seoUrl}
        meta={[
          {
            name: 'robots',
            content:
              'max-image-preview:large, max-snippet:-1, max-video-preview:-1',
          },
        ]}
        isBreadcrumbs
        secondLevel="Blog"
        secondLevelPath="/blog/"
        current={h1 || ''}
        currentPath={`/blog/tag/${kebabCase(url || '')}/`}
      />
    </>
  )
}

const Tags = ({ data, pageContext }: PagePropsWithContext<TagsQueryResult>) => (
  <TagsView data={data} pageContext={pageContext} />
)

export default Tags

export const pageQuery = graphql`
  query ArticleTags(
    $tag: String
    $published: [Boolean]
    $skip: Int!
    $limit: Int!
  ) {
    strapiTag(name: { eq: $tag }) {
      name
      meta_title
      h1
      url
      description {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      title: meta_title
      meta_description
      og_image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    allStrapiArticle(
      sort: { modified_date: DESC }
      filter: {
        active: { eq: true }
        tags: { elemMatch: { name: { eq: $tag } } }
        published: { in: $published }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          publication_date(formatString: "DD MMM YYYY")
          modified_date(formatString: "DD MMM YYYY")
          id
          url
          strapi_id
          views
          preview_image_alt
          category {
            name
          }
          body {
            data {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
          }
          title
          meta_description
          published
        }
      }
    }

    allStrapiTag(sort: { number_tag: ASC }) {
      edges {
        node {
          id
          name
          url
          number_tag
          articles {
            active
          }
        }
      }
    }

    allStrapiCategory(sort: { order: ASC }) {
      edges {
        node {
          name
          nav_name
          id
        }
      }
    }
  }
`
